@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background: #0a0e14;
  color: #eff2ff;
  font-family: "Montserrat", sans-serif;
}
/*::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/
.webkitText {
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: calc(1em / 16);
  -webkit-background-clip: text;
}
.webkitRadius {
  -webkit-border-radius: 10px;
}
